const skywardUrl = 'https://skyward.io';
const skywardLink = `<a href=${skywardUrl} target="_blank" rel="noopener noreferrer">Skyward</a>`;
const flightInsightsUrl = 'https://skyward.io/new-skyward-feature-uas-flight-insights/';
const flightInsightsLink = `<a href=${flightInsightsUrl} target="_blank" rel="noopener noreferrer">Flight Insights</a>`;

const skywardBlog = [
  `At ${skywardLink}, our cloud application helps drone program administrators and pilots plan and manage aircraft operations at scale in sectors as diverse as construction, agriculture and energy. As the drone industry grows and matures, we evolve our software to help our customers plan, visualize, and document flights and safely access airspace. As a member of the Skyward engineering team, it’s my goal to help our users chart their drone flights and maintain compliance. That means that sometimes we’re called upon to think like cartographers and librarians, as well as engineers.`,
  'A good feature should help a user solve a problem or accomplish a series of tasks — simply, elegantly and reliably. Over time, we learn more about how our customers use our software, which illuminates better pathways for us to help them accomplish their tasks. Our UX team surfaces feature requests by interviewing and working directly with our customers and flight industry professionals. And our customer success team works directly with each customer to surface any problematic workflows. Then, our product managers triage the urgency of each request, and articulate the details of each feature to the designers and developers. The smaller the feedback loops, the better.',
  `This past year, Skyward Engineering released ${flightInsightsLink} — a bird’s eye view of the frequency and geographic location of drone flights built on the highly performant Mapbox GL JS. This library uses lightweight data formats called tiled vectors to display geographical information in a web browser, allowing us to efficiently render key data points regarding crew, aircraft, and battery life. Our users can quickly filter and navigate flight data, which helps them monitor their drone program operations and assess return on investment.`,
  'Flight Insights delights our customers and is our all-time most stable feature. This would not have been possible without several key engineering initiatives which contributed to its successful development. The Front End Engineering team uses a best-in-class optimization tool called Webpack which allows us to combine our application code into a bundle of JavaScript, fonts, images, and styles that can be loaded on demand or in parallel within a web browser. This “code splitting” means that Flight Insights responds quickly to user interactions and performs well on desktop computers and mobile devices.',
  'Webpack allows us to generate a single static file, or “bundle” of code that comes alive once it reaches the browser. When first downloaded from the server, the JavaScript code is in a “minified” format — stripped of unnecessary comments and spaces that make the code easier to read and work with while under development. Since the JavaScript interpreter doesn’t require code to be visually legible, we can save some space by removing information that the computer doesn’t care about.',
  'Because our entire front end application reduces into one single file, we can quickly and easily deploy real-time instances of our features while under development for the quality assurance, design, and product teams to continually review. Providing such visibility into each incremental change allowed us to build Flight Insights with a high degree of speed and quality.',
  'In order to create a clean and coherent interface for our features, we must implement good state management. State is data that allows us to model and respond to user interactions — this controls what the user can see and do. Our Front End team uses a library called Redux to help us write applications that manage data predictably and consistently. We can easily visualize when, where, why, and how our application’s state changes over time. We can then test each piece of our application and how it responds to every user interaction.',
  'The term “code complete” is a moving target and our job as software engineers is never finished. Software can always be improved, so the moment a piece of code is written, it could very well be considered legacy code. The less we interact with a piece of code and the less we know about how it works, the longer it takes to change it or add to it.',
  'We want to avoid a build up of hard-to-manage “legacy code.” For a product manager, the mere mention of legacy code spells bad news — it’s difficult to predict how long development work may take within unfamiliar codebases. Since we’re constantly improving our user experience, we want to be able to quickly and reliably maintain, reorganize, and reuse our core application data and visual components. We can then safely deprecate legacy code while continually releasing new features like Flight Insights.',
];

export default skywardBlog;
